<template>
<Card>
    <template #content>
		<div class="p-m-500">
			<p>Seleccionar el perfil de importación de cargos y el archivo a procesar:</p>
            <Divider />
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-fluid p-field p-ml-3">
					<label for="perfil" class="p-text-bold">Perfil de Importación</label>
					<Dropdown v-model="id_perfil" :options="perfiles" optionLabel="perfil" optionValue="id_perfil" @change="actualizarIdentificadores($event)"/>
				</div>	
			</div>	
			<Divider />
			<div class="p-fluid p-formgrid p-grid">		
				<div class="p-field p-ml-3">
					<label for="archivo" class="p-text-bold">Archivo de Pagos</label>
					<FileUpload ref="updArchivo" :multiple="false" :customUpload="true" @uploader="cargarArchivo($event)" @error="onUploadError" @upload="goNextPage" chooseLabel="Seleccionar Archivo..." :showUploadButton="false"
					 :showCancelButton="false" :fileLimit="1" accept="text/*" invalidFileLimitMessage="El número máximo de archivos permitidos es 1">
						<template #empty>
							<p>Arraste y suelte el archivo a importar en el recuadro</p>
						</template>
					</FileUpload>					
				</div>	
			</div>	
		</div>
    </template>
    <template #footer>
		<div class="p-d-flex p-jc-end">
		<Button label="Siguiente" icon="pi pi-angle-right" @click="nextPage()"  :disabled="!puedeContinuar" icon-pos="right"/>	
		</div>
    </template>
</Card>	
<Dialog v-model:visible="verificandoDlg" :showHeader="true" :modal="true" :closable="false">			
	<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
		<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
			<img src="/assets/layout/images/view.gif" width="64" height="64"/>
		</div>		
		<div class="p-d-flex p-ai-center p-jc-center p-text-bold">
			<div class="text-lg">Por favor espere un minuto mientras verificamos el archivo y su registros..</div>
		</div>
	</div>
</Dialog>
</template>
<script>
import CxcService from '../../service/cxc.service';
import { CURRENT_APP_MODE } from '../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'CxcImpSeleccionPerfil',
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id_perfil: '',
					id_moneda: '',
					errores: false,
					archivo: '',
					movs: []					
				}
			}
		}
	},
	components: {
		Divider
	},
	data() {        
        return {
            appMode: CURRENT_APP_MODE,
            verificandoDlg: false,
			archivo: null,
            perfiles: [],
			id_perfil: null,	
			id_moneda: this.formData.id_moneda,
			errores: this.formData.errores,
			id_empleado: this.$store.state.auth.currentAppCtx.id_empleado,	
			procesandoDlg: false,
        }
    },
    mounted() {
		this.loading = true;

		CxcService.getPerfilesImportacion(this.$store.state.auth.currentAppCtx).then(
			data => {
				this.perfiles = data;
				if(this.perfiles.length > 0) {
					this.id_perfil = this.perfiles[0].id_perfil;
					this.id_moneda = this.perfiles[0].id_moneda;
				}

				this.loading = false;
			}
		).catch(
			data => {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}
		)		            
    },
	computed: {
		puedeContinuar(){
			if ((this.id_perfil) && (this.$refs.updArchivo.files.length > 0))
				return true
			else
				return false;
		}
	},
    methods: {
		async cargarArchivo(e) {
			this.verificandoDlg = true;
			try {
				var doc =  e.files[0];

				var complemento = {
					id_perfil: this.id_perfil,
					id_empleado: this.id_empleado
				};
				
				let movs = await CxcService.cargarArchivoPagos(this.$store.state.auth.currentAppCtx, complemento, doc);
				let errores = movs.filter(e => {return e.error});

				let formData = {
					id_perfil: this.id_perfil,
					id_moneda: this.id_moneda,					
					archivo: this.archivo,
					errores: (errores.length > 0),
					movs: (errores.length==0?movs:errores)
				};

			   	this.$emit('nextPage', {condiciones: formData, pageIndex: 0});
				
				this.verificandoDlg = false;
			} catch (err) {
				this.verificandoDlg = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}	
		},
		nextPage(){
			this.$refs.updArchivo.upload();
		}
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>